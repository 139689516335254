import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  let token = JSON.parse(sessionStorage.user || '{}').name
  if(token){
    if(to.path === '/login'){
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (['/login'].indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
    }
  }
});


export default router
