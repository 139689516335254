<template>
  <div class="index">
    <div class="left">
      <div class="left_top">
        <img style="width:100%;height:40px" src="../../public/img/logo.png" alt="">
        <!-- <span>上 海 地 矿</span> -->
      </div>
      <el-menu size="mini" default-active="首页" text-color="#fff" active-text-color="#fff" background-color="transparent"
        class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
        <el-menu-item index="首页" @click="click_menu_index()">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item v-for="(item, index) in list" :key="index" :index="item.name" @click="click_menu(item)">
          <i :class="item.icon ? item.icon : 'el-icon-menu'"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <div class="header" style="height: 15px;">
        <div class="header_left" style="display:flex;align-items: center">
          金华-义乌-东阳市域轨道交通工程运营结构监测委外维保服务I标（金义线）项目信息化系统
          <img style="width:20px;height:20px;margin-top: 4px;" src="../../public/img/shdk.png" alt="">
          <!-- <el-breadcrumb size="mini" separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadcrumbName}}</el-breadcrumb-item>
          </el-breadcrumb> -->
        </div>
        <div class="header_right">
          <i class="el-icon-user-solid"></i>
          {{ user.name }}
          <el-button style="margin-left: 20px;color:brown;" type="text" size="mini" @click="login_out">退出登录</el-button>
        </div>
      </div>
      <div class="index_content" v-if="index">
        <div class="index_contentTop" style="text-align: center;">
          <img style="width:50%;height:100%" src="../../public/img/content_left.png" alt="">
          <img style="width:50%;height:100%" src="../../public/img/content_right.png" alt="">
        </div>
        <div class="index_contentBottom" style="margin-top: 30px;">
          <p style="text-indent: 2em;margin-top: 10px;"><span
              style="font-weight: 600;font-size: 20px;">金华轨道交通</span>（Jinhua Rail
            Transit）是服务于中国浙江省金华市的城市轨道交通系统，其首条线路金华轨道交通金义东线分别于2022年8月30日及2022年12月28日开通金义段和义东段（首通段）试运营。</p>
          <p style="text-indent: 2em;margin-top: 10px;">
            截至2023年4月，金华轨道交通共有1条线路运营，为金华轨道交通金义东线（金义段和义东段）；线路全长107.17千米，已开通车站28座。</p>
          <p style="text-indent: 2em;margin-top: 10px;">截至2023年4月，金华轨道交通共有1条线路在建，为金华轨道交通金义东线（义东段后通段）。</p>
          <p style="text-indent: 2em;margin-top: 10px;">截至2023年年底，金华轨道交通全线总客流量达3974.66万人次，日均客流量为13万人次左右
            ；2023年4月28日至5月3日的97.35万人次客运量为最高值 ；截至2023年7月，金华轨道交通日均客流量约为10万人次。</p>
        </div>
      </div>
      <div class="content" v-if="!index">
        <el-tabs type="border-card" v-model="name">
          <el-tab-pane v-for="(item, index) in subList" :key="index" :name="item.name" :label="item.name">
            <!-- {{ item.type }} -->
            <div class="table" v-if="item.type == 'table'">
              <el-table :data="item.data" border style="width: 100%" size="mini">
                <el-table-column :width="items.field == 'files' ? '' : '140px'" v-for="(items, indexs) in item.headers"
                  :key="indexs" :prop="items.field" :label="items.title">
                  <template slot-scope="scope">
                    <!-- <span v-if="items.field == 'files'">{{ scope.row[items.field][0].name }}</span> -->
                    <a :href="scope.row[items.field][0].url" target="_blank">
                      <div v-if="items.field == 'files'" class="fileCard">
                        <div class="title_img">
                          <img v-if="scope.row[items.field][0].type == 'pdf'" src="../../public/img/pdf.png" alt="">
                          <img v-if="scope.row[items.field][0].type == 'zip'" src="../../public/img/zip.png" alt="">
                        </div>
                        <div style="display:flex;justify-content: space-between;flex-direction: column;">
                          <span>{{ scope.row[items.field][0].name }}</span>
                          <span style="color:brown">大小:{{ scope.row[items.field][0].size }}</span>
                        </div>

                      </div>
                    </a>
                    <span v-if="items.field != 'files'">{{ scope.row[items.field] }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100" v-if="item.isOrginal != '0'">
                  <template slot-scope="scope" >
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看原始数据</el-button>
                  </template>
              </el-table-column>
              </el-table>
            </div>
            <div class="card_list" v-if="item.type == 'card'">
              <!-- {{ item.name }} -->
              <el-card class="card" v-for="(items, indexs) in item.data" :key="indexs">
                <div slot="header" class="clearfix">
                  <div style="font-weight: 600;line-height: 12px">
                    <el-badge v-if="!items.orginalUrl" :value="items.files.length" class="item">
                      | {{ items.title }}
                    </el-badge>
                    <span v-if="items.orginalUrl"> | {{ items.title }}</span>
                  </div>
                  <div style="margin-top: 10px;display:flex;justify-content: space-between;">
                    <span style="font-size: 12px;color:#999">{{ items.company }}</span>
                    <span style="font-size: 12px;color:#999">{{ items.time }}</span>
                  </div>
                  <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="card_child" v-for="(itemss, indexss) in items.files" :key="indexss">
                  <a :href="itemss.url" target="_blank">
                    <div class="fileCard">
                      <div class="title_img">
                        <img v-if="itemss.type == 'pdf'" src="../../public/img/pdf.png" alt="">
                        <img v-if="itemss.type == 'zip'" src="../../public/img/zip.png" alt="">
                      </div>
                      <div style="display:flex;justify-content: space-between;flex-direction: column;padding:10px 0;">
                        <span>{{ itemss.name }}</span>
                        <span style="color:brown">大小:{{ itemss.size }}</span>
                      </div>
                    </div>
                  </a>
                </div>
                <el-button v-if="items.orginalUrl" @click="handleClick(items)" type="text" size="small">查看原始数据</el-button>
                <div v-if="items.files.length <= 0 && !items.orginalUrl">暂无数据</div>
              </el-card>
              <!-- <div class="card" v-for="(items,indexs) in item.data" :key="indexs" v-if="items.files.length">
                  
                </div> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 查看原始数据 -->
    <el-drawer title="" size="70%" :visible.sync="drawer" :with-header="false">
        <div class="orginalData">
          <el-tabs v-model="activeName" size="mini">
            <el-tab-pane size="mini" v-for="(item,index) in orginalData" :key="index" :label="item.title" :name="item.title">
              <div class="card_list">
                  <div class="fileCard" v-for="(items, indexs) in item.files" :key="indexs">
                    <!-- <a :href="item.urlPrefix + '/' + items.name" target="_blank" style="display:flex;color:#409eff"> -->
                    <div class="title_img">
                      <a :href="item.urlPrefix + '/' + items.name" target="_blank" style="display:flex">
                      <img style="width:50px" v-if="items.type != 'txt' && items.type != 'jpg'" src="../../public/img/file.png" alt="">
                      <img style="width:50px" v-if="items.type == 'txt'" src="../../public/img/txt.png" alt="">
                      <!-- <img style="width:50px" v-if="items.type == 'jpg'" @click.stop="big_img(item.urlPrefix + '/' + items.name)" :src="item.urlPrefix + '/' + items.name" alt=""> -->
                    </a>
                      <el-image 
                        v-if="items.type == 'jpg'"
                        style="width: 50px; height: 50px"
                        :src="item.urlPrefix + '/' + items.name + '?x-oss-process=image/resize,h_100,m_lfit'" 
                        :preview-src-list="[item.urlPrefix + '/' + items.name]">
                      </el-image>
                    </div>
                    <a :href="item.urlPrefix + '/' + items.name" target="_blank" style="display:flex">
                      <div style="display:flex;justify-content: space-between;flex-direction: column;padding:10px 0;margin-left:5px;">
                        <span class="name_span" style="color:#409eff">{{ items.name }}</span>
                        
                        <span style="color:brown" class="size_span">大小:{{ items.size }}</span>
                      </div>
                    </a>
                  <!-- </a> -->
                  </div>
                
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      activeName:'',
      name: '',
      list: [],
      subList: [],
      breadcrumbName: '',//面包屑选中
      user: {},
      index: true,
      orginalData:[], //抽屉数据
      drawer:false, //是否展开原始数据
      type:'',
    }
  },
  created() {
    this.user = JSON.parse(sessionStorage.user)
    this.fetchData();
  },
  mounted() {

  },
  methods: {
    //查看大图
    big_img(url){

    },
    //首页
    click_menu_index() {
      console.log('点首页')
      this.index = true;
    },
    //退出登录
    login_out() {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        sessionStorage.clear();
        this.$router.push({ path: "/login" });
      }).catch(() => {

      });

    },
    //切换菜单
    click_menu(item) {
      console.log(item,1111)
      this.breadcrumbName = item.name;
      this.subList = item.sub;
      this.name = this.subList[0].name;
      this.type = item.type;
      this.index = false;
    },
    //查看原始数据
    async handleClick(row) {
      try {                               
        const response = await axios.get(row.orginalUrl);
        let orginal = response.data;
        orginal.forEach(item => {
            // 合并当前对象的 files
            const mergedFiles = Object.values(item.files).flat();
            item.files = mergedFiles; // 将合并后的文件数组添加到对象中
        });
        this.orginalData = orginal;
        if(!this.orginalData[0].title) this.orginalData[0].title = '全部原始数据'
        this.activeName = this.orginalData[0].title;//默认渲染第一个
        this.drawer = true;
        console.log(this.orginalData,1111);        
      } catch (error) {
        // console.error('1111', error);
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    async fetchData() {
      try {                               
        const response = await axios.get('https://cjf-nbjc-backstage.oss-cn-hangzhou.aliyuncs.com/config/fileConfig.json');
        // const response = await axios.get('/config/fileConfig.json');
        this.list = response.data.projects;
        console.log(list,1111)
        this.breadcrumbName = this.list[0].name;
        this.subList = this.list[0].sub;
        this.name = this.subList[0].name;
        this.type = this.list[0].type
        this.index = true;
        // sessionStorage.user = JSON.stringify(this.user)
      } catch (error) {
        // console.error('1111', error);
      }
    }
  }
}
</script>
<style lang="less" scoped>
/**原始数据集 */
.orginalData{
  padding:10px;
  .card_list{
    display:flex;
    width:100%;
    overflow-y: scroll;
    flex-wrap: wrap;
    max-height: calc(100vh - 80px);
    .fileCard{
      display:flex;
      width:20%;
      margin-bottom: 10px;
      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .name_span{
        -webkit-line-clamp: 2;
      } 
      .size_span{
        -webkit-line-clamp: 1;
      } 
    }
    .title_img {
      display: flex;
      align-items: center;
      
      /* 垂直居中 */
    }
  }
  /deep/.el-tabs {
    display: flex;
    width: 100%;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
      padding: 0;
    }
  }
  /deep/.ceshi{
    // width:calc(100vw - 140px) !important;
    // font-size: 100px;
  }
}
.index {
  display: flex;

  .left {
    .left_top {
      display: flex;
      justify-content: center;
      /* 水平居中 */
      align-items: center;
      /* 垂直居中 */
    }

    width:140px;
    height:100vh;
    display:flex;
    flex-direction: column;

    .el-menu {
      width: 100%;
      height: 100vh;
      border-right: none;
      background-image: url('/img/aside_bg_image.png');
      /* 图片背景 */
      background-size: 100% 100%;
      flex: 1;
    }

    .el-menu-item:hover {
      background-color: rgb(62, 94, 181) !important;
      box-shadow: 0 2px 12px 0 rgb(62, 94, 181);
    }

    .el-menu-item.is-active {
      background-color: rgb(62, 94, 181) !important;
      box-shadow: 0 2px 12px 0 rgb(62, 94, 181);
    }

    .el-dropdown-menu__item,
    .el-menu-item {
      font-size: 10px !important;
    }

  }

  .right {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;

    /deep/.el-badge__content.is-fixed {
      right: 0;
    }

    /deep/.el-badge__content {
      height: auto;
    }

    /deep/.el-card__body {
      max-height: 190px;
      overflow-y: scroll;
    }

    /deep/.el-breadcrumb__inner {
      font-weight: 600 !important;
    }

    .header {
      display: flex;

      .header_right {
        flex: 1;
        text-align: right;

        .el-button--text {
          padding: 0 !important;
        }
      }

    }

    .index_content {
      padding-top: 14px;
      flex: 1;
      display: flex;
      overflow-y: scroll;
      max-height: calc(100vh - 35px);
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .content {
      padding-top: 14px;
      flex: 1;
      display: flex;
      overflow-y: scroll;
      max-height: calc(100vh - 35px);

      /deep/.el-tabs--border-card {
        display: flex;
        width: 100%;
        flex-direction: column;

        .el-tabs__content {
          flex: 1;
          padding: 0;
          overflow-y: scroll;
        }
      }

      a {
        color: #409eff;
        // text-align: center;
      }

      .fileCard {
        display: flex;

        // flex-direction: column;
        // justify-content: center; /* 水平居中 */
        // align-items: center; /* 垂直居中 */
        a {
          color: #409eff;
          // text-align: center;
        }

        .title_img {
          display: flex;
          align-items: center;
          /* 垂直居中 */
        }

        img {
          width: 50px;
          height: 60px;
        }

        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .card_list {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .card {
          margin-top: 10px;
          padding: 10px;
          // border:1px solid #409eff;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>